import React from "react"

import { CountUp } from "countup.js"

import CarneIcon from "../images/carne.png"
import CartIcon from "../images/cos-cumparaturi.png"
import DocumentIcon from "../images/document.png"
import GrupIcon from "../images/grup.png"
import LegumeIcon from "../images/legume.png"
import SmileyIcon from "../images/smiley-face.png"
import Angajati from "../images/angajati-penny.png"
import Cifra from "../images/cifra-afaceri.png"
import LedIcon from "../images/led.png"
import Magazine from "../images/magazine.png"
import Voluntari from "../images/voluntari.png"
import Pig from "../images/pig.png"

import ArrowLeft from "../images/arrow-left.svg"
import ArrowRight from "../images/arrow-right.svg"

class NumbersEnergie extends React.Component {
  componentDidMount() {
    const self = this

    $("#numbers-carousel")
      .slick({
        slidesToShow: 3,
        prevArrow: ".numbers-arrow-left",
        nextArrow: ".numbers-arrow-right",
        infinite: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      })
      .on("afterChange", function(event, slick, currentSlide, nextSlide) {
        self.runNumbers()
      })

    this.runNumbers()
  }
  
  // eslint-disable-next-line class-methods-use-this
  runNumbers() {
    $(".counter").each(function() {
      const item = $(this)
      const end = item.attr("data-end")
      const start = item.attr("data-start")

      const count = new CountUp(this.id, end, { startVal: start, useGrouping: true, decimal: ',', separator: '.' })
      count.start()
    })
  }

  render() {
    return (
      <div className="penny-numbers-container mt-5">
        <h1 className="text-success text-center akko-bold py-3">
          PENNY în cifre
        </h1>
        <div className="bg-success">
          <div className="container position-relative py-5">
            <img
              className="numbers-arrow numbers-arrow-left"
              src={ArrowLeft}
              alt=""
            />
            <div id="numbers-carousel">
              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Magazine} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-produse"
                      className="counter"
                      data-end="11"
                      data-start="0"
                    >
                      11
                    </span>
                    %
                  </h2>
                  <p className="text-white">
                    reducere emisiilor CO2-eq
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={LedIcon} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-cons"
                      className="counter"
                      data-end="6"
                      data-start="0"
                    >
                      6
                    </span>
                    %
                  </h2>
                  <p className="text-white">scădere consum specific de energie electrică</p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={LedIcon} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-produser"
                      className="counter"
                      data-end="6242"
                      data-start="0"
                    >
                      6242
                    </span>{" "}
                    MWh
                  </h2>
                  <p className="text-white">
                    energie verde produsă intern cu 64 sisteme de panouri fotovoltaice
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={Magazine} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-cat"
                      className="counter"
                      data-end="0"
                      data-start="0"
                    >
                      0
                    </span>{" "}
                    magazine proprii
                  </h2>
                  <p className="text-white">
                    încălzite pe bază de combustibili fosili
                  </p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={LedIcon} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-angajati"
                      className="counter"
                      data-end="53"
                      data-start="0"
                    >
                      53
                    </span>{" "} amplasamente
                  </h2>
                  <p className="text-white">dotate cu stații de încărcare pentru mașini electrice</p>
                </div>
              </div>

              <div>
                <div className="penny-number-body text-center">
                  <div className="penny-number-icon">
                    <img className="mx-auto" src={DocumentIcon} alt="" />
                  </div>
                  <h2 className="akko-bold text-white">
                    <span
                      id="counter-instruire"
                      className="counter"
                      data-end="23"
                      data-start="0"
                    >
                      23
                    </span>{" "}de magazine
                  </h2>
                  <p className="text-white">
                    certificate BREEAM in use
                  </p>
                </div>
              </div>
            
            </div>

            <img
              className="numbers-arrow numbers-arrow-right"
              src={ArrowRight}
              alt=""
            />
          </div>
        </div>
      </div>
    )
  }
}

export default NumbersEnergie
